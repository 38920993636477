/* responsive ******************************************************************************/
@media (max-width: 1599px) {

    #transactionSection  .card-header button {
        font-size: 16px;
    }
    #chatSection .chat-box-right .box-footer .nav-link {
        padding: 10px 15px;
        font-size: 16px;
    }
    .detail p {
        font-size: 16px;
    }
    .payment-card h5 {
        font-size: 18px;
    }
}
/* responsive ******************************************************************************/
@media (max-width: 1399px) {

    /* Left side bar  */


    #profileSection .common-form .btn-common {
        font-size: 14px;
    }
    
    a.nav-link.demo .icon-box {
        padding: 12px;
        margin-right: 15px;
    }
    /* Dashboard page  */

    #dashboard .card-body .text-box p.card-title {
        font-size: 20px;
    }
    #dashboard .card-body .text-box p:not(.number) {
        font-size: 17px;
    }
    #dashboard .card-body .text-box p.number {
        font-size: 20px;
    }
    .common-form .detail p {
        font-size: 12px;
    }
    
    #chatSection .user-chat-nav .user-chat-box a .user-info h5, .chat-box-right .contact-profile h5 {
        font-size: 18px;
    }
    
    #chatSection  .chat-box-right .box-footer .input-field {
        flex-grow: 1;
    }
    .payment-card h5 {
        font-size: 16px;
    }
    .payment-card button.upi-btn {
        padding: 7px 34px ;
    }
    #userSection .payment-card .img-box {
        margin-right: 10px;
    }
    #userSection .payment-card .img-box img {
        max-width: 85%;
    }

    /* userTransactionSection */
    #userTransactionSection #nav-myID .btn-common {
        font-size: 16px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .user-info h5 {
        font-size: 19px;
    }
    #userTransactionSection .payment-card .user-detail-box .img-box img {
        max-width: 50px;
    }

}
/* responsive ******************************************************************************/
@media (max-width: 1199px) {

    h5 {
        font-size: 21px;
    }
    .sub-header .form-field-wrapper  .form-control-lg {
        font-size: 15px;
    }
    .form-select {
        background-size: 9px 8px;
    }
    /* .date-box {
        column-gap: 12px;
        flex-wrap: wrap;
    } */

    /* .date-box .col {
        min-width: 150px !important;
    } */
    #dashboard .dashboard-subbox .row {
        padding: 30px 10px;
    }
    /* transaction page  */

    #transactionSection  .card-header button {
        padding: 9px 12px;
        font-size: 18px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .btn-common,
    #transactionSection .payment-card .btn-common {
        padding: 9px 17px;
        font-size: 18px;
    }
     #transactionSection .payment-card .user-info h5 {
        font-size: 19px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .img-box,
    #transactionSection .payment-card .img-box {
        padding: 10px;
    }

    #chatSection  .chat-box-right .box-footer .input-field .test {
        padding: 10px 10px;
        font-size: 14px;
    }
    #chatSection  nav {
        padding: 15px 8px;
    }
    #chatSection  .nav-link {
        padding: 9px;
        font-size: 13px;
        flex-grow: 1;
    }
  
    #chatSection  .form-group .form-control {
        padding: 10px 10px 10px 50px;
    }
    #chatSection  .serach-button-tab .form-group i {
        left: 5%;
        font-size: 16px;
    }
    
    #chatSection .chat-box-right .msg_cotainer {
        max-width: 50%;
        font-size: 15px;
        padding: 8px 15px 8px 10px;
    }
    #chatSection  .msg_cotainer_send {
        max-width: 50%;
        font-size: 15px;
        padding: 8px 8px 10px 15px;
    }
    #chatSection .chat-box-right .contact-profile .profile-setting {
        margin-right: 0;
    }
    #chatSection .user-chat-nav .user-chat-box a .user-info h5, .chat-box-right .contact-profile h5 {
        font-size: 16px;
    }

    #chatSection  .chat-box-right .box-footer .input-field a img {
        max-width: 30px;
    }
    
    #chatSection .chat-box-right .box-footer .nav-link {
        font-size: 14px;
        padding: 10px;
    }
    #chatSection .user-chat-nav .user-chat-box a .user-info{
        margin-left: 10px;
        white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    }
    #chatSection .user-chat-nav .user-chat-box a .user-info p {
        font-size: 14px;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
    }
    #chatSection .user-chat-nav .user-chat-box .time {
        font-size: 11px;
    }
    #chatSection .user-chat-nav  .nav-link {
        padding: 5px;
    }
  
    .detail p {
        font-size: 14px;
    }
    .payment-card button.nav-link {
        padding: 10px 15px;
    }
    .payment-card button.upi-btn {
        padding: 7px 34px !important;
    }
   
}
/* responsive ******************************************************************************/
@media (max-width: 1024px) {

    
    .modal  .modal-body .img-box  img.qr-code {
        max-width: 30%;
    }
    .modal  .modal-body .text-box p.bank-upi-id{
        font-size: 15px;
    }
    #notificationSection .nav-tabs .nav-link h6,
    #blockUserSection .user-info h6 {
        font-size: 16px;
    }
    .payment-card h5 {
        font-size: 16px;
    }
    #paymentSection .row {
        margin-top: 20px;
    }
}
    #userSection .nav-tabs {
        row-gap: 10px;
    }
    /* #userSection  .serach-button-tab .form-group i {
        left: 8%;
    } */
    #userSection  .serach-button-tab .nav-link, #chatSection  .nav-link {
        padding: 10px 15px ;
    }
    #userSection .serach-button-tab  .form-group .form-control{
        padding: 15px 15px 15px 40px;
    }

    .empty-data-message .text-box span {
        font-size: 18px;
    }


/* responsive ******************************************************************************/
@media (max-width: 991px) {

    .right-sidebar .main-header-box .close-menu{
        color: var(--white);
        display: block;
        font-size: 23px;
    }


    .nav-logo .close-btn {
        display: block;
        position: absolute;
        top: 50%;
        right: -75px;
        transform: translateY(-50%);
        background-color: var(--black);
        padding: 5px 10px;
        border: 2px solid var(--white);
        color: var(--white);
        border-radius: 5px;
    }

    .left-sidebar {
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 9999;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }
    .right-sidebar.show-leftbar .close-menu {
        opacity: 0;
    }
    .left-sidebar.show-leftbar {
        left: 0;
        opacity: 1;
    }

    .right-sidebar {
        margin-left: 0px;
    }

    .left-sidebar.show-leftbar .sm-logo {
        display: none;
    }

    .left-sidebar.show-leftbar .full-logo {
        display: block;
    }

    .right-sidebar.show-leftbar {
        margin-left: 0px;
    }

    .right-sidebar.show-leftbar .main-header-box {
        margin-left: 0;
        z-index: 1;
    }

    .left-sidebar.show-leftbar .nav-link span {
        display: block;
    }

    .left-sidebar.show-leftbar {
        width: 250px;
    }

    .nav-link {
        display: flex;
        align-items: center;
    }

    .left-sidebar.show-leftbar .nav-item {
        display: block;
        justify-content: flex-start;
    }

    .right-sidebar {
        width: 100%;
        overflow: auto;
    }

    .left-sidebar.show-leftbar .nav-link i {
        margin-right: 8px;
    }

    /* header responsive */

    #client .filter-box {
        display: block;
    }

    .visitor-box {
        margin: 15px 0;
    }

    .visitor-box .form-control {
        display: inline;
    }


    /* Login page  */
    .main-wrapper .login-box  .card  .logo-box img {
        max-width: 80%;
    }
    .main-wrapper .login-box .card .card-title h3 {
        font-size: 28px;
    }

    .right-sidebar .sub-header {
        display: block;
    }

    .date-box .col {
        min-width: 155px !important;
    }

    
    #paymentSection .row {
        margin-top: 40px;
    }
    /* .payment-card button.nav-link {
        padding: 12px 25px;
    } */

    .modal {
        left: 0;
    }

    .modal  .modal-body .img-box  img.qr-code {
        max-width: 40%;
    }
    .modal  .modal-body .text-box p.bank-upi-id{
        font-size: 20px;
    }
    #notificationSection .nav-tabs .nav-link h6,
    #blockUserSection .user-info h6 {
        font-size: 18px;
    }
    #blockUserSection .button-box .btn-common {
        font-size: 16px;
    }
    /* userTransactionSection */
    #userTransactionSection .nav-tabs .nav-link {
        padding: 10px 15px;
    }

    .mobile-navbar {
        position: fixed;
        bottom: 0;
        width: 100vw;
        opacity: 1;
        display: block;
    }


  
}
/* responsive ******************************************************************************/
@media (max-width: 767px) {
    .right-sidebar .contant-box {
        padding: 20px 20px;
    }
    .right-sidebar .contant-box .advertisement-img-box {
        border-radius: 6px;
        margin-bottom: 25px;
    }
    #chatSection .chat-box-right {
        display: none;
    }

    .userChatBox .chat-box-right {
        display: block !important;
    }
    #chatSection .chat-box-right.mobile-chat {
        display: block;
    }
   
    #userSideDepositSection  .common-form .button-box {
        margin-top: 55px;
    }
    #userTransactionSection .payment-card .user-detail-box .img-box {
        margin-right: 10px;
    }
    #userTransactionSection .nav-tabs .nav-link {
        font-size: 16px;
    }

    .mobile-navbar .nav-bar-nav .nav-item a.nav-link.demo {
        font-size: 17px;
    }
    .mobile-navbar a.nav-link.demo .icon-box {
        height: 56px;
        width: 56px;
    }
                #chatSection .chat-box-right .box-footer .input-field .chat-control a {
                    margin-left: 7px;
                }
}
/* responsive ******************************************************************************/
@media (max-width: 575px) {

    #profileSection .common-form .button-box ul {
        justify-content: center;
        column-gap: 12px;
    }

    .main-wrapper .login-box  .card  .logo-box img {
        max-width: 70%;
    }
    .main-wrapper .login-box  .card .card-body {
        margin-top: 0px;
    }
    #dashboard .dashboard-subbox .row-1 h5 {
        font-size: 22px;
    }
    .right-sidebar .profile-box .btn.dropdown-toggle {
        padding: 0;
    }
    .right-sidebar .main-header-box h5 {
        font-size: 21px;
    }
    .sub-header .form-field-wrapper .form-control-lg {
        font-size: 14px;
        padding: 18px 10px;
    }
    .sub-header  .date-box .col {
        max-width: 168px;
    }

    .detail-box{
        position: static;
        margin-top: 12px;
    }

    .common-form .button-box{
        margin-top: 25px;
    }
    #userSideDepositSection  .common-form .button-box {
        margin-top: 10px;
    }
                #chatSection .chat-box-right .box-footer .input-field .chat-control a img {
                    height: 25px;
                    width: 25px;
                }
}
/* responsive ******************************************************************************/
@media (max-width: 479px) {
    br{
        display: none;
    }

    #profileSection .common-form  .input-group .image {
        height: 107px;
        max-width: 107px;
    }
    
    
    #profileSection .common-form .input-group .image img {
        max-width: 50px;
    }
    
    #profileSection .common-form .btn-common svg {
        height: 18px;
        width: 24px;
        min-width: 18px;
    }
    
    #profileSection .common-form .btn-common {
        font-size: 12px;
    }

    #notificationSection .nav-tabs .nav-link span {
        font-size: 14px;
    }
	#notificationSection .nav-tabs .nav-link i {
    font-size: 9px;
    }

    .btn-common {
        column-gap: 5px;
    }
    .main-wrapper .login-box .form-group {
        margin-bottom: 10px;
    }
    .main-wrapper .login-box .card .card-title h3 {
        font-size: 21px;
    }
    .main-wrapper .login-box  .card .card-title p {
        font-size: 15px;
    }

    .right-sidebar .main-header-box {
        padding: 13px 16px;
    }
    .right-sidebar .contant-box {
        padding: 20px 10px;
    }

 
    .left-sidebar .nav-bar-nav {
        padding: 0;
    }
    .left-sidebar.show-leftbar {
        width: 215px;
    }
    .nav-logo .close-btn {
        right: -50px;
    }
    a.nav-link.demo {
        padding: 7px 10px;
    }

    .date-box .col {
        max-width: 100%;
    }
    .sub-header  .date-box .col {
        max-width: 100%;
    }
    .sub-header .form-field-wrapper .form-control-lg {
        font-size: 12px;
        padding: 15px 10px;
    }
    #chatSection .chat-box-right .contact-profile .call-setting-tab > a {
        height: 35px;
        width: 35px;
    }
    /* #chatSection .chat-box-right .contact-profile .call-setting-tab {
        margin-right: 0;
    } */
    #chatSection .chat-box-right .contact-profile .call-setting-tab > a > i {
        font-size: 13px;
    }
    #chatSection .chat-box-right .box-footer .input-field a img{
        max-width: 24px;
    }
  
    #chatSection .chat-box-right .msg_cotainer,
    #chatSection .msg_cotainer_send  {
        max-width: 70%;
    }
    #chatSection .chat-box-right .contact-profile img {
        width: 15%;
    }
    #chatSection .chat-box-right .box-footer .nav-link {
        font-size: 11px;
    }
    #chatSection  .chat-box-right .box-footer .input-group {
        column-gap: 5px;
    }
    #chatSection  .chat-box-right .box-footer .input-field .test {
        font-size: 13px;
    }
    /* transaction page  */
    .right-sidebar .sub-header {
        padding: 0 0 0 0;
    }
    #transactionSection .transaction-list-box {
        gap: 10px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .user-info p,
    #transactionSection .payment-card .user-info p {
        font-size: 12px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .user-info h5,
    #transactionSection .payment-card .user-info h5 {
        margin-bottom: 5px;
        font-size: 17px;
    }
    #transactionSection  .card-header button {
        padding: 5px 8px;
        font-size: 17px;
    }
    .user-dashborad .card-header button {
        font-size: 14px !important;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .btn-common,
    #transactionSection .payment-card .btn-common {
        padding: 5px 8px;
        font-size: 17px;
    }
    /* #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .img-box img, */
    #transactionSection .payment-card .img-box img {
        max-width: 100%;
        width: 29px;
        height: 27px;
    }
    #userTransactionSection #nav-Transaction .payment-card .user-detail-box .img-box img{
        max-width: 100%;
        width: 29px;
        height: 27px;
    }
    #transactionSection .box-heading h5 {
        font-size: 20px;
    }


    .right-sidebar .contant-box .advertisement-img-box {
        margin-bottom: 25px;
        border-radius: 5px;
    }
  
    .payment-card h5 {
        font-size: 15px;
    }
    .payment-card button.nav-link {
        padding: 10px 15px;
        font-size: 15px;
    }
    .payment-card button.upi-btn {
        padding: 7px 40px !important;
    }
    .detail p {
        font-size: 13px;
        margin-bottom: 6px;
    }
    .common-form .input-group .form-control, .common-form .input-group .form-select, .common-form .input-group textarea {
        padding: 10px 16px;
        font-size: 14px;
    }
    .common-form  .btn-common {
        padding: 11px 26px;
        font-size: 16px;
    }
    .common-form .detail p {
        margin-bottom: 4px;
        font-size: 13px;
    }

    .common-form .radio-box .form-check .form-check-input {
        height: 20px;
        width: 20px;
    }


    #userSection  .serach-button-tab .nav-link, #chatSection  .nav-link {
        font-size: 15px;
    }

    .modal button {
        padding: 8px 0;
    }
    .modal-body form .input-group .form-control {
        font-size: 14px;
        padding: 8px 6px;
       
    }

    .modal  .modal-body .img-box  img.qr-code {
        max-width: 30%;
    }
    .modal  .modal-body .text-box p.bank-upi-id{
        font-size: 15px;
        margin-top: 10px;
    }
    #notificationSection .nav-tabs .nav-link .payment-card{
        padding: 10px 8px;
    }
    #notificationSection .nav-tabs .nav-link {
        margin-bottom: 10px;
    }
    #userSection .payment-card .img-box {
        margin-right: 0px;
    }
    #userSection .payment-card .img-box img {
        max-width: 70%;
    }
  
    #blockUserSection{
        padding: 20px 0;
    }
    #blockUserSection .button-box .btn-common {
        padding: 10px;
        font-size: 14px;
    }
    #blockUserSection .img-box img {
        width: 75%;
    }
    #notificationSection .nav-tabs .nav-link h6, #blockUserSection .user-info h6 {
        font-size: 15px;
    }
    #blockUserSection .user-info h6 {
        font-size: 16px;
    }
    /* userTransactionSection */

    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .img-box, #transactionSection .payment-card .img-box {
        margin-right: 5px;
    }
    #userTransactionSection .nav-tabs .nav-link {
        font-size: 14px;
    }
    #userTransactionSection #nav-myID .btn-common {
        padding: 8px 0;
    }
    #userTransactionSection .payment-card .user-detail-box .img-box img {
        max-width: 40px;
    }
    #userTransactionSection #nav-myID .btn-common svg{
        height: 17px;
        width: 15px;
    }
    #userTransactionSection #nav-Transaction .payment-card .button-row h5 {
        font-size: 13px;
    }
    #userTransactionSection #nav-Transaction .payment-card .button-row h5 svg {
        width: 15px;
    }
    #userTransactionSection #nav-myID .btn-common {
        font-size: 12px;
    }
    .mobile-navbar .nav-bar-nav {
        justify-content: space-around;
    }
    .mobile-navbar a.nav-link.demo .icon-box {
        height: 46px;
        width: 46px;
    }

    #userSideWithdrawSection .common-form .button-box {
        margin-top: 60px;
    }

    .empty-data-message .logo-box img, .modal  .modal-body .logo-box img {
        max-width: 20%;
    }
    .empty-data-message .text-box  span {
        font-size: 15px;
        padding-top: 20px;
    }
}
/* responsive ******************************************************************************/
@media (max-width: 375px) {

    .main-wrapper .verification-box  .otp-field input {
        width: 38px;
        height: 38px;
    }

    #notificationSection .nav-tabs .nav-link span {
        font-size: 13px;
    }

    .left-sidebar .nav-logo .full-logo img {
        max-width: 120px;
    }
    .nav-logo .close-btn {
        right: -63px;
    }

    a.nav-link.demo svg {
        height: 20px;
        width: 20px;
    }
    .left-sidebar.show-leftbar .nav-link > span {
        font-size: 16px;
    }
    .nav-link span.badge {
        font-size: 8px;
        left: 24px;
        width: 20px;
        height: 20px;
    }
    .right-sidebar .main-header-box h5 {
        font-size: 18px;
    }
    #dashboard .dashboard-subbox .row-1 .card .card-body{
        text-align: center;
    }
    .date-box .col {
     padding: 5px;
    }

    /* transaction  */
    .transaction-sub-header .date-box > div {
        min-width: 150px !important;
        padding: 0 10px;
    }

    .user-dashborad  .card-header {
        flex-wrap: wrap;
        row-gap: 15px;
      
    }
   

    .sub-header .form-field-wrapper .form-control-lg {
        font-size: 12px;
        padding: 7px 10px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .user-info p,
    #transactionSection .payment-card .user-info p {
        font-size: 11px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .user-detail-box .btn-common,
    #transactionSection .payment-card .btn-common {
        padding: 5px 8px;
        font-size: 13px;
    }
    #transactionSection .box-heading h5 {
        font-size: 19px;
    }
    #transactionSection  .card-header button {
        font-size: 14px;
    }

    #chatSection .chat-box-right .contact-profile img {
        width: 14%;
    }
    .chat-box-right .contact-profile h5 {
        font-size: 15px;
    }
    #chatSection  .chat-box-right .box-footer .input-group {
        column-gap: 15px;
        column-gap: 5px;
        row-gap: 10px;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #chatSection  .chat-box-right .box-footer .input-field {
        flex-grow: 1;
        width: 100%;
    }

    span.input-group-btn {
        width: 49%;
    }
    #chatSection .chat-box-right .box-footer .nav-link {
        justify-content: center;
    }

    .sub-header .form-field-wrapper {
        margin: 0 0 10px 0;
    }

    .modal-dialog {
        margin: 20px;
    }
    .common-form .input-group, .modal-body form .input-group {
        padding-bottom: 12px;
    }
    .modal-body form .input-group .form-control {
        font-size: 12px;
        padding: 6px 6px;
    }
    .modal button {
        padding: 3px 0;
    }
    .common-form .form-check .form-check-input {
        height: 20px;
        width: 20px;
        border-radius: 4px;
    }
    .common-form label.form-check-label {
        font-size: 14px;
    }
    #blockUserSection .nav-tabs .nav-link{
    padding: 4px;
    }
    #userTransactionSection .payment-card .user-detail-box .img-box img {
        max-width: 30px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .button-row .link, #userTransactionSection .payment-card  .user-detail-box .link {
        font-size: 13px;
    }

    .mobile-navbar .nav-bar-nav .nav-item a.nav-link.demo {
        font-size: 14px;
        padding: 7px 0;
    }
    .mobile-navbar a.nav-link.demo .icon-box {
        height: 40px;
        width: 40px;
    }

    .empty-data-message .text-box span {
        font-size: 13px;
    }
  

}

@media (max-width: 320px){

    body{
        overflow: scroll !important;
    }
    .main-wrapper .login-box .card .logo-box img {
        max-width: 60%;
    }
    .main-wrapper .login-box .card .card-title h3 {
        font-size: 21px;
    }
    .main-wrapper .login-box  .card .card-title p {
        font-size: 15px;
    }
    .main-wrapper .login-box .card .card-body {
        margin-top: 0px;
    }
    .main-wrapper .login-box .form-group {
        margin-bottom: 20px;
    }
    .right-sidebar .main-header-box h5 {
        font-size: 15px;
    }
    .date-box .col {
        max-width: 168px;
        min-width: 150px !important;
    }

    #transactionSection .payment-card .user-info h5 {
        margin-bottom: 5px;
        font-size: 15px;
    }
    .chat-box-right .contact-profile .user-info {
        margin-left: 6px;
    }
    #chatSection .chat-box-right .contact-profile .call-setting-tab > a {
        height: 25px;
        width: 25px;
    }
    #chatSection .chat-box-right .contact-profile .call-setting-tab > a > i {
        font-size: 10px;
    }
    #chatSection .chat-box-right .contact-profile img {
        width: 12%;
    }

    
    .modal {
        position: absolute;
    }
    .modal#myModal-1 {
        top: 40px;
    }
    .payment-card h5 {
        font-size: 13px;
    }

    .payment-card button.nav-link {
        padding: 7px 13px;
    }
    .payment-card button.upi-btn {
        max-width: 80px;
        font-size: 13px;
    }
    #userTransactionSection .button-row {
        column-gap: 6px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .button-row .img-box img {
        width: 29px;
        height: 29px;
    }
    #userTransactionSection  #nav-Transaction .payment-card  .button-row .link, #userTransactionSection .payment-card  .user-detail-box .link {
        font-size: 12px;
    }
    
}