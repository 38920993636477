/* common css ******************************************************************************/



/* Inter  */
@font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "Inter-Medium";
    src: url("../fonts/Inter-Medium.ttf");
}

@font-face {
    font-family: "Inter-SemiBold";
    src: url("../fonts/Inter-SemiBold.ttf");
}

/* Fredoka */
@font-face {
    font-family: "Fredoka-Regular";
    src: url("../fonts/Fredoka-Regular.ttf");
}

:root {
    --white: #fff;
    --black: #000;
    --light-black: #00000045;
    --orange-E67A30: #4ADADA;
    --grey-9A9EAC: #9A9EAC;
    --light-orange-D67367: #4ADADA;
    --border-color: #333844;
    --dark-bg-color: #1F2128;
    --card-light-bg: #383B45;
    --skyblue: #30A4E6;
    --green: #3FE630;
    --red: #E63030;
    --yellow: #C6BF1A;
    --text-color: #686B76;
    --light-blue-bg-color: #21222D;
    --card-bg-color: #171821;
    --active-color: #494F61;
    --light-yellow: #E6DF30;

}

/* Common Use css start  */

.orange-color {
    background: var(--orange-E67A30);
    color: var(--black) !important;
}

.white {
    color: var(--white);
}

.black {
    color: var(--black);
}

.green {
    color: var(--green);
}

.bg-green {
    background-color: var(--green);
    color: var(--black) !important;

}

.skyblue {
    color: var(--skyblue);
}

.yellow {
    color: var(--yellow);
}

.bg-yellow {
    background-color: var(--light-yellow);
    color: var(--black) !important;
}

.bg-black {
    background-color: var(--black);
    color: var(--white) !important;
}

.red {
    color: var(--red);
}

.bg-red {
    background-color: var(--red);
    color: var(--black) !important;

}

/* Common Use css End  */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

.common::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
}

body {
    background: var(--black);
    font-family: "Inter-Medium";

}

body.show {
    position: fixed;
    top: 0;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    line-height: 1.2;
    color: var(--white);
    font-weight: 700;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
    margin-bottom: 0;
}

h1 {
    font-size: 52px;
}

h2 {
    font-size: 44px;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 28px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 20px;
}

p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 25px;
    color: var(--white);
}

p:last-child {
    margin-bottom: 0;
}

.small {
    font-size: 16px;
}

ul,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    text-transform: capitalize;
    display: inline-block;
    line-height: 1.2;
    color: inherit;
    font-family: inherit;
}

a:hover {
    color: inherit !important;
}

span {
    display: inline-block;
}

.section-heading {
    text-align: center;
    margin-bottom: 50px;
}

.btn-common {
    font-size: 20px;
    padding: 12px 30px;
    color: #cbccd0;
    border-radius: 13px;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}
.btn-common img {
    max-width: 30px;
    margin-right: 10px;
}

button:focus {
    box-shadow: none !important;
}

section {
    /* padding-bottom: 100px; */
    overflow: hidden;
}

.bg {
    padding: 100px 0;
}

.mobile {
    display: none !important;
}

.desktop {
    display: block !important;
}

img,
video {
    max-width: 100%;
}

.button-box {
    padding-top: 20px;
}

.button-box ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.owl-theme .owl-dots .owl-dot span {
    background: var(--black);
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: var(--white);
    outline: 2px solid var(--white);
    outline-offset: 3px;
}

.owl-nav {
    margin-top: 0;
}

.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.owl-nav button.owl-prev {
    left: 0;
}

.owl-nav button.owl-next {
    right: 0;
}

.form-control-common {
    background: var(--white);
    border: 1px solid var(--black);
    font-size: 20px;
    padding: 15px 50px 15px 20px;
    width: 100%;
    appearance: textfield;
    color: var(--black);
    border-radius: 0;
    box-shadow: none;
    resize: none;
    outline: none;
}

.input-group {
    padding-bottom: 30px;
}

input:focus {
    box-shadow: none !important;
}

::placeholder {
    color: #6c757d !important;
}

.form-select:focus {
    border: none !important;
    outline: 0 !important;
    box-shadow: 0 !important;
}

#scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: var(--dark-bg-color);
    margin-left: 5px;
}

#scrollbar::-webkit-scrollbar {
    width: 6px;
    background-color: var(--dark-bg-color);
    display: none;
}

#scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--active-color);
}


/* Log in page css ******************************************************************************/


.main-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    position: relative;
}

.main-wrapper::before {
    content: "";
    position: absolute;
    width: 190px;
    height: 190px;
    background: var(--orange-E67A30);
    bottom: 0;
    left: 0;
    border-radius: 50%;
    filter: blur(211px);
}

.main-wrapper::after {
    content: "";
    position: absolute;
    width: 190px;
    height: 190px;
    background: var(--orange-E67A30);
    top: 0;
    right: 0;
    border-radius: 50%;
    filter: blur(211px);
}

.main-wrapper .login-box .card {
    background-color: transparent !important;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0;
}

.main-wrapper .login-box .card .logo-box {
    margin-bottom: 20px;
}

.main-wrapper .login-box .card .logo-box img {
    width: 100%;
    max-width: 90%;
}

.main-wrapper .login-box .card .card-title h3 {
    color: var(--white);
    font-weight: 500;
    font-family: "Inter-SemiBold";
    font-size: 34px;

}

.main-wrapper .login-box .card .card-title p {
    font-size: 18px;
    font-family: "Inter-Regular";
    color: var(--grey-9A9EAC);
    margin-bottom: 0;

}

.main-wrapper .login-box .card .card-body {
    margin-top: 15px;
}

.main-wrapper .login-box .form-group {
    margin-bottom: 20px;
}

.main-wrapper .login-box .card .form-label {
    color: var(--grey-9A9EAC);
    font-size: 15px;
}

.main-wrapper .login-box .card .form-label span {
    color: var(--light-orange-D67367);
}

.main-wrapper .login-box .card .form-control {
    background-color: var(--dark-bg-color);
    border-color: #333844;
    color: var(--grey-9A9EAC);
    border-radius: 8px;
    padding: 10px;
}

.main-wrapper .login-box .card .card-body .btn {
    background-color: var(--orange-E67A30);
    color: var(--black);
    font-weight: 500;
    border-radius: 8px;
    padding: 10px 12px;
    font-family: "Inter-SemiBold";
    width: 100%;
    margin-top: 10px;
}

.main-wrapper .login-box .form-group .toggle-password {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -30px;
    color: var(--grey-9A9EAC);
}


/* verification page  */


.main-wrapper .verification-box .change-nb {
    margin-top: 15px;
}

.main-wrapper .verification-box .change-nb a {
    color: var(--white);
    border-bottom: 1px solid var(--white);

}

.main-wrapper .verification-box .otp-field {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 15px 0;
}

.main-wrapper .verification-box .otp-field input {
    width: 44px;
    height: 44px;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    margin: 2px;
    border: 1px solid var(--border-color);
    background: var(--dark-bg-color);
    font-weight: bold;
    color: #fff;
    outline: none;
    transition: all 0.1s;
}

/* .otp-field input:focus {
    border: 2px solid #a527ff;
    box-shadow: 0 0 2px 2px #a527ff6a;
} */

.main-wrapper .verification-box .card .card-title p.number {
    color: var(--white);
    padding-top: 10px;
}

.main-wrapper .verification-box .card p.otp {
    color: var(--white);
    text-align: center;
    margin-bottom: 0;
    font-size: 15px;
}


/* index page css start ------------------------------------------------------------------------------------------------------------------------*/

.container-box {
    display: flex;
}

.container-fluid {
    padding: 0;
}

.card {
    /* background-color: #F9F9F9;
    box-shadow: 0 -3px 31px 0 rgba(0,0,0,.05),0 6px 20px 0 rgba(0,0,0,.02); */
    height: 100%;
}



/* --- Left sidebar css */
.left-sidebar {
    /* height: auto; */
    min-height: 100vh;
    width: 100%;
    max-width: 250px;
    background-color: var(--dark-bg-color);
    position: fixed;
    top: 0;
    left: 0;
    border-right: 1px solid var(--text-color);
    z-index: 111110;
}

.left-sidebar.show-leftbar {
    width: 80px;

}

.left-sidebar .nav-logo {
    margin: 25px auto;
    /* padding: 15px 0; */
    position: relative;
}

.left-sidebar .nav-logo .full-logo img {
    width: 100%;
    max-width: 150px;
}

.left-sidebar .nav-bar-nav {
    width: 100%;
    padding: 20px 0;
}

.nav-link {
    color: #fff !important;
    font-size: 18px;
    margin-bottom: 10px;
}

.nav-link.active {
    background-color: var(--orange-color);
}

.nav-link:hover {
    background-color: var(--orange-color);
}

.nav-link span {
    font-family: "Inter-Medium";
    color: var(--text-color);
}

.nav-link.active span {
    color: var(--white);
}

.nav-link span.badge {
    color: var(--white);
    font-size: 10px;
    left: 27px;
    top: 9px;
    position: absolute;
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 15px;

}

.nav-link i {
    margin-right: 8px;
}

.sm-logo {
    display: none;
}

.left-sidebar.show-leftbar .nav-link span {
    display: none;
}

.left-sidebar.show-leftbar .nav-link i {
    margin-right: 0px;
}

.left-sidebar.show-leftbar .sm-logo {
    display: block;
    padding: 15px 10px;
}

.left-sidebar.show-leftbar .sm-logo img {
    width: 100%;
    max-width: 50px;
}

.left-sidebar.show-leftbar .full-logo {
    display: none;
}

.left-sidebar.show-leftbar .nav-item {
    display: flex;
    justify-content: center;
}



a.nav-link.demo {
    color: var(--text-color);
    font-size: 18px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

a.nav-link.demo .icon-box {
    padding: 15px;
    margin-right: 10px;
    border-radius: 13px;
    transition: all 0.3s ease-out;

}

a.nav-link.demo svg {
    fill: var(--text-color);
    transition: all 0.6s ease-out;
}

a.nav-link.demo:hover svg {
    fill: var(--black);
}

a.nav-link.demo:hover .icon-box {
    background: var(--orange-E67A30);
}

a.nav-link.demo.active .icon-box {
    background: var(--orange-E67A30);

}

a.nav-link.demo.active svg {
    fill: var(--black)
}

/* --- Right sidebar css */

.right-sidebar {
    width: 100%;
    background-color: var(--black);
    margin-left: 250px;
    min-height: 100vh;
    overflow: auto;
}

.right-sidebar.show-leftbar {
    margin-left: 80px;
}

/* .right-sidebar section {
    padding: 20px;
} */

.right-sidebar .profile-box img {
    width: 100%;
    max-width: 50px;
    border-radius: 50px;
}

.right-sidebar .main-header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 35px;
    position: sticky;
    top: 0;
    z-index: 111111;
    background: var(--dark-bg-color);
}

.right-sidebar .main-header-box .close-menu {
    color: var(--white);
    display: none;
}

.right-sidebar .main-header-box .close-menu i {
    font-size: 20px;
}

.right-sidebar .profile-box .dropdown-toggle::after {
    display: none;
}

.right-sidebar .profile-box .btn.dropdown-toggle {
    box-shadow: none;
}

.right-sidebar .main-header-box h5 {
    color: var(--white);
    text-transform: uppercase;
    font-family: "Inter-Medium";
    margin-bottom: 0;
    font-weight: 500;

}

.right-sidebar .contant-box {
    padding: 30px 30px;
    background-color: var(--black);
    min-height: calc(100vh - 90px);
}

.right-sidebar .contant-box .advertisement-img-box {
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 40px;
    height: 300px;
}
.right-sidebar .contant-box .advertisement-img-box img{
    height: 100%;
    width: 100%;
}
.right-sidebar .sub-header {
    padding: 0 0 15px 0;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.date-box .col {
    min-width: 150px !important;
}

.last-child {
    flex: 0 0 1%;
}

.sub-header .form-field-wrapper {
    margin: 0 0 20px 0;
}

.sub-header .date-box-2 {
    column-gap: 20px;
    /* display: none !important; */
}

.sub-header .form-field-wrapper .form-control-lg {
    font-size: 16px;
    border-radius: 15px;
    background-color: var(--dark-bg-color);
    padding: 18px 20px;
    border: none;
    color: var(--white);
}

.sub-header .form-field-wrapper .form-control-lg:focus {
    box-shadow: none;
}

.form-select {
    background-image: url('../images/down-icon.png');
    background-size: 15px 10px;
}

.form-select:focus {
    box-shadow: 0 !important;
}

.sub-header .form-field-wrapper .form-control-lg[type="button"] {
    background-color: var(--orange-E67A30);
    color: var(--black);
}

#dashboard .dashboard-subbox {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

#dashboard .dashboard-subbox .row {
    padding: 30px 20px;
    background: var(--dark-bg-color);
    border-radius: 20px;
    row-gap: 20px;
}

#dashboard .dashboard-subbox .row-1 h5 {
    color: var(--white);
    font-size: 25px;
    font-family: "Inter-SemiBold";


}

#dashboard .dashboard-subbox .row-1 .card {
    background: var(--card-light-bg);
    border-radius: 15px;
}

#dashboard .dashboard-subbox .row-1 .img-box {
    margin-bottom: 15px;
}

#dashboard .card-body .text-box p:not(.number) {
    margin-bottom: 0;
    color: var(--white);
    font-size: 19px;
}

#dashboard .card-body .text-box p.card-title {
    font-size: 22px;
}

#dashboard .card-body .text-box p.number {
    font-size: 22px;
}

/* Transaction section css ******************************************************************************/

.transaction-sub-header .date-box>div {
    flex-grow: 1;
    min-width: 178px !important;
}

.transaction-sub-header .date-box>div:last-child {
    flex-grow: 0;
}

#transactionSection .box-heading h5 {
    font-weight: 500;
    font-size: 24px;
}

#transactionSection .card-header {
    margin-bottom: 15px;
    justify-content: space-between;
}

#transactionSection .card-header button {
    padding: 12px 22px;
    border-radius: 13px;
}

#transactionSection .transaction-list-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: calc(100vh - 340px);
    height: calc(100vh - 340px);
    overflow-y: auto;
}

/* .user-dashborad .transaction-list-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: calc(100vh -  475px)!important;
    height: calc(100vh - 475px) !important;
    overflow-y: auto;
} */

#transactionSection .transaction-list-box::-webkit-scrollbar-track {
    width: 5px;
}

#transactionSection .transaction-list-box::-webkit-scrollbar-thumb {
    width: 5px !important;
}

#transactionSection .payment-card {
    padding: 8px 10px;
}

#userTransactionSection #nav-Transaction .payment-card .user-detail-box .img-box,
#transactionSection .payment-card .img-box {
    padding: 15px;
    background: var(--black);
    border-radius: 18px;
    margin-right: 15px;
}

#userTransactionSection #nav-Transaction .payment-card .user-detail-box .img-box img,
#transactionSection .payment-card .img-box img {
    width: 36px;
    height: 32px;
    max-width: 100%;
}

#userTransactionSection #nav-Transaction .payment-card .user-detail-box .user-info h5,
#transactionSection .payment-card .user-info h5 {
    margin-bottom: 10px;
    font-size: 22px;
    text-transform: capitalize;
}

#userTransactionSection #nav-Transaction .payment-card .user-detail-box .user-info p,
#transactionSection .payment-card .user-info p {
    font-size: 16px;
}

#userTransactionSection #nav-Transaction .payment-card .user-detail-box .btn-common,
#transactionSection .payment-card .btn-common {
    padding: 12px 30px;
}


/* chat section css ******************************************************************************/
#chatSection .chat-box-right .box-footer .input-field .chat-control {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
}

#chatSection .chat-box-right .box-footer .input-field .chat-control a {
    display: inline-block;
    margin-left: 15px;
}

#chatSection .chat-box-right .box-footer .input-field .chat-control a img {
    height: 30px;
    width: 30px;
    max-width: 100%;
}

#userTransactionSection nav,
#chatSection nav {
    background: var(--light-blue-bg-color);
    padding: 15px 15px;
    border-radius: 15px !important;
    margin-bottom: 0;
}

/* #chatSection  .nav-tabs {
    display: flex;
    justify-content: space-between;
} */
#userSection .serach-button-tab .nav-link,
#chatSection .nav-link {
    border: none !important;
    padding: 15px;
    color: var(--white);
    border-radius: 15px;
    font-family: "Inter-Medium";

}

#userTransactionSection .nav-tabs .nav-link.active,
#chatSection .nav-tabs .nav-link.active {
    color: var(--black) !important;
    background-color: var(--orange-E67A30);
    border: none !important;
    border-radius: 13px;
}

.serach-button-tab {
    margin: 20px 0;
}

.serach-button-tab .form-group {
    position: relative;
}

.serach-button-tab .form-group i {
    position: absolute;
    top: 50%;
    left: 5%;
    color: var(--white);
    font-size: 18px;
    transform: translateY(-50%);
}

#userSection .serach-button-tab button.nav-link {
    color: var(--black) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-weight: 800;
    border-radius: 10px;
    height: 100%;
}

#userSection .serach-button-tab button.nav-link:hover {
    background: var(--orange-E67A30);
}

/* #userSection  .serach-button-tab .form-group i{
    left: 3%;
} */
#userSection .serach-button-tab .form-group .form-control,
#chatSection .form-group .form-control {
    background: var(--light-blue-bg-color);
    color: var(--white) !important;
    padding: 15px 15px 15px 60px;
    margin-bottom: 0;
}

#chatSection .serach-button-tab .nav-link.btn {
    color: var(--black) !important;
    /* padding: 15px 50px !important; */
}

#chatSection .serach-button-tab .nav-link.btn:hover {
    background: var(--orange-E67A30);
}

#chatSection .user-chat-nav {
    background: var(--black) !important;
    padding: 0;
    height: calc(100vh - 320px);
    overflow-y: auto;
}

#chatSection .user-chat-nav .nav-tabs {
    display: block;
}

#chatSection .user-chat-nav .nav-link {
    border: none !important;
    padding: 10px;
    color: var(--white);
    border-radius: 15px;
    font-family: "Inter-Medium";
    background: var(--light-blue-bg-color);
    width: 100%;
    margin-bottom: 15px;
}

#chatSection .user-chat-nav .user-chat-box {
    width: 100%;
}

#chatSection .user-chat-nav .user-chat-box a .img-box img {
    border-radius: 12px;
    object-fit: cover;
    max-width: 90%;

}

#chatSection .user-chat-nav .user-chat-box a .user-info,
.chat-box-right .contact-profile .user-info {
    margin-left: 15px;
}

#chatSection .user-chat-nav .user-chat-box a .user-info h5,
.chat-box-right .contact-profile h5 {
    margin-bottom: 0;
    color: var(--white);
    font-size: 20px;
    font-family: "Inter-SemiBold";

}

#chatSection .user-chat-nav .user-chat-box a .user-info p {
    color: var(--white);
    font-size: 17px;
    font-family: "Fredoka-Regular";

}

#chatSection .user-chat-nav .user-chat-box .time {
    color: var(--white);
    margin-bottom: 0;
    font-size: 14px;
}

#chatSection .user-chat-nav .chat-time span {
    height: 25px;
    width: 25px;
    line-height: 25px;
    border: 1px solid #000;
    border-radius: 50%;
    background-color: var(--orange-E67A30);
    color: var(--black);
    font-size: 11px;
}

#chatSection .user-chat-nav .nav-link.active {
    color: var(--white) !important;
    background-color: var(--active-color);
    border: none !important;
    border-radius: 13px;
}

#chatSection .user-chat-nav .nav-link.active .user-info h5,
#chatSection .user-chat-nav .nav-link.active .chat-time .time,
#chatSection .user-chat-nav .nav-link.active .user-info p {
    color: var(--black);
}

#chatSection .user-chat-nav .nav-link.active .chat-time span {
    background-color: var(--black);
    color: var(--orange-E67A30);
}

#chatSection .user-chat-nav .nav-link.uncheck {
    background-color: var(--orange-E67A30);
}

#chatSection .user-chat-nav .nav-link.uncheck .chat-time span {
    background-color: var(--black);
    color: var(--orange-E67A30);
}

#chatSection .user-chat-nav .nav-link.uncheck .user-info h5,
#chatSection .user-chat-nav .nav-link.uncheck .chat-time .time,
#chatSection .user-chat-nav .nav-link.uncheck .user-info p {
    color: var(--black);
}

/* Right Chat box  */


#chatSection .chat-box-right .contact-profile,
.card-header {
    background: var(--black);
    padding: 7px;
    display: flex;
    align-items: center;
    border-radius: 12px !important;

}

#chatSection .chat-box-right .contact-profile img {
    border-radius: 12px;
    max-width: 100%;
}


#chatSection .chat-box-right .contact-profile .call-setting-tab {
    margin-left: auto;
    margin-right: 10px;
    display: flex;
}

#chatSection .chat-box-right .contact-profile .call-setting-tab>a {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: var(--light-blue-bg-color);
    position: relative;
    margin-left: 10px;
}

#chatSection .chat-box-right .contact-profile .call-setting-tab>a:hover {
    color: transparent;
}

#chatSection .chat-box-right .contact-profile .call-setting-tab>a>i {
    color: var(--white);
    font-size: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#chatSection .chat-box-right .contact-profile .call-setting-tab .dropdown-menu i {
    padding: 10px;
    background: var(--light-blue-bg-color);
    border-radius: 50px;
    margin-right: 12px;
}

#chatSection .chat-box-right .contact-profile .call-setting-tab .dropdown-menu .dropdown-item:active {
    background-color: var(--active-color);
}

#chatSection .chat-box-right {
    padding: 15px 13px;
    background: url('../images/chat-bg.png');
    background-position: center;
    background-size: 100% 100%;
    border-radius: 15px;
    /* height: calc(100vh - 150px); */
}

#chatSection .chat-box-right .contact-profile .profile-setting {
    background: var(--light-blue-bg-color);
    padding: 15px;
    margin-right: 20px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: grid;
    place-items: center;
}

#chatSection .chat-box-right .contact-profile .profile-setting i {
    color: var(--white);
}

/* Chat inbox part  */
#chatSection .chat-box-right .msg_card_body {
    height: calc(100vh - 315px);
    overflow-y: auto;
}
#chatSection .chat-box-right .msg_card_body.chat-support{
    height: calc(100vh - 230px);
}

.msg_card_body_2 {
    height: calc(100vh - 223px) !important;

}

#chatSection .chat-box-right .msg_card_body>div {
    margin-bottom: 20px;
}

#chatSection .chat-box-right .msg_cotainer {
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 15px 15px 15px 5px;
    background-color: var(--white);
    padding: 15px 25px 15px 10px;
    position: relative;
    color: var(--black);
    max-width: 40%;

}

#chatSection .chat-box-right .img_cont_msg {
    max-width: 40%;
    margin-bottom: 15px;
    margin-top:10px;
}

#chatSection .msg_cotainer_send {
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 15px 15px 5px 15px;
    background-color: var(--orange-E67A30);
    padding: 15px 10px 15px 25px;
    position: relative;
    color: var(--black);
    max-width: 40%;

}

#chatSection .msg_time {
    position: absolute;
    left: 0;
    bottom: -20px;
    color: var(--white);
    font-size: 12px;
   
}

#chatSection .msg_time_send {
    position: absolute;
    right: 0;
    bottom: -20px;
    color: var(--white);
    font-size: 12px;
    width: 57px;
}

#chatSection .chat-box-right .box-footer {
    padding-top: 10px;
}

#chatSection .chat-box-right .box-footer .nav-link {
    border-radius: 50px;
    color: var(--black) !important;
    padding: 8px 40px;
    height: 100%;
}

#chatSection .chat-box-right .box-footer .nav-link img {
    height: 23px;
    width: 30px;
}

#chatSection .chat-box-right .box-footer .nav-link:hover {
    background: var(--orange-E67A30);
}

#chatSection .chat-box-right .box-footer .input-field {
    flex-grow: 2;
    position: relative;

}

#chatSection .chat-box-right .box-footer .input-group {
    column-gap: 10px;
    flex-wrap: nowrap;
}

#chatSection .chat-box-right .box-footer .input-field .test {
    padding: 10px 20px;
    border: none;
    width: 100%;
    border-radius: 50px;
}

#chatSection .chat-box-right .box-footer .input-field .test:focus-visible {
    border: none;
    outline: none;
}

/* #chatSection .chat-box-right .box-footer .input-field a {
    position: absolute;
    right: 57px;
    top: 50%;
    transform: translateY(-50%);
}

#chatSection .chat-box-right .box-footer .input-field a:last-child {
    right: 5px;
} */

/* Payment section css ******************************************************************************/


.payment-card {
    padding: 15px 13px;
    background: var(--dark-bg-color);
    background-position: center;
    background-size: cover;
    border-radius: 15px;
}
.payment-card-border{
    border-radius: 5px !important;
}

#paymentSection .row {
    margin-top: 50px;
}

.payment-card button.nav-link {
    padding: 12px 40px;
    color: var(--black) !important;
    border-radius: 10px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.payment-card h5 {
    font-size: 20px;
    font-weight: 400;
}

.detail p {
    margin-bottom: 10px;
    font-size: 18px;
}

.detail p:first-child {
    font-family: "Inter-Regular";
}

.detail p:last-child {
    font-family: "Inter-Medium";
}

.payment-card button.nav-link:hover {
    background: var(--orange-E67A30);
}

.payment-card button.upi-btn {
    border-radius: 15px;
    font-weight: 400;
    width: 100%;
    max-width: 124px;
    padding: 7px 40px;

}

.payment-card button.active-green {
    background: var(--green);
}

.payment-card button.active-green:hover {
    background-color: var(--green);
}

.payment-card button.active-black {
    background: var(--black);
    color: var(--white) !important;

}

.payment-card button.active-black:hover {
    background-color: var(--black);
}

.payment-card .card-btn-box img {
    width: 70%;
}

.upi-detail>div {
    padding: 15px 0;
}

.upi-detail>div:not(:last-child) {
    border-bottom: 1px solid var(--text-color);

}

.upi-detail p {
    margin-bottom: 0;
}


/* Modal  css  */


.modal {
    position: fixed;
    left: 7%;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal button.btn-close {
    position: absolute;
    right: 14px;
    top: 14px;
    opacity: 1;
    background-image: url('../images/modal-close-btn.png');
    background-position: center;
    background-size: contain;
}

.modal-backdrop.show {
    opacity: 0.8 !important;
}

.modal .payment-card {
    padding: 0;
    /* overflow: hidden; */
    border: 1.5px solid var(--border-color);
}

.modal .payment-card .modal-header {
    padding: 20px 0;
    justify-content: center;
    border: 0;
    background: var(--light-black);
}

.modal .modal-body .logo-box img {
    max-width: 14%;
}

.modal .modal-body .text-box span {
    color: var(--white);
    text-align: center;
    font-family: "Inter-Regular";
}

.modal .modal-body .text-box p.bank-upi-id {
    color: var(--grey-9A9EAC);
    /* font-size: 15px; */
    margin-top: 20px;
}

.modal .modal-body .img-box img.qr-code {
    width: 100%;
    max-width: 40%;
}

.common-form .form-label,
.modal .modal-body .form-label {
    color: var(--text-color);
    font-size: 14px;
}

.common-form .input-group,
.modal-body form .input-group {
    padding-bottom: 15px;
}

.common-form .input-group .form-control,
.common-form .input-group .form-select,
.common-form .input-group textarea,
.modal-body form .input-group .form-control {
    background: var(--dark-bg-color);
    border: 1px solid var(--border-color);
    color: var(--text-color);
    border-radius: 8px !important;
    font-size: 16px;
    width: 100%;
    padding: 12px 6px;
}

.common-form .input-group .form-control,
.common-form .input-group .form-select,
.common-form .input-group textarea {
    border: none;
    color: var(--white);
    padding: 14px 16px;
}

.common-form .input-group .form-select {
    background-image: url('../images/down-icon.png');
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
}

.common-form .chekbox {
    display: flex;
    align-items: center;
    gap: 20px;
}

.common-form .form-check {
    display: flex;
    align-items: center;
    margin-left: 0;
}

.common-form .form-check .form-check-input {
    float: none;
    margin-left: -1.5em;
    height: 30px;
    width: 30px;
    border-radius: 8px;
    border: none;
    background-color: var(--dark-bg-color);

}

.common-form .radio-box .form-check .form-check-input {
    border-radius: 50%;
    background-color: var(--black);
    border: 1px solid var(--white);
    height: 25px;
    width: 25px;
}

.common-form label.form-check-label {
    color: white;
    margin-left: 10px;
    font-family: "Inter-Regular";

}

.common-form .form-check-input:checked {
    /* background-color: var(--dark-bg-color); */
    border: none;
}

.common-form .input-group .image {
    width: 150px;
    position: relative;
    background: #1F2128;
    max-width: 150px;
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.common-form .input-group .image input.form-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.common-form .input-group .image img {
    max-width: 50px;
}

.common-form .btn-common {
    padding: 10px 35px;
    border-radius: 8px;
}

.common-form .input-group textarea:focus {
    outline: none;
}

.detail-box {
    position: absolute;
    top: 101%;
    left: 0;
}

.status {
    position: relative !important;
}

.Pending::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 52%;
    height: 10px;
    width: 10px;
    background: var(--yellow);
    border-radius: 50%;
    z-index: 99;
}

.Rejected::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 52%;
    height: 10px;
    width: 10px;
    background: var(--red);
    border-radius: 50%;
    z-index: 99;
}

.Accepted::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 52%;
    height: 10px;
    width: 10px;
    background: var(--green);
    border-radius: 50%;
    z-index: 99;
}

#notificationSection .nav-tabs .nav-link .payment-card {
    padding: 10px 10px;
    width: 100%;
}

#notificationSection .nav-tabs .nav-link .payment-card.uncheck {
    border: 1px solid var(--orange-E67A30);
    background-color: var(--uncheck-color);
}

#notificationSection .nav-tabs .nav-link {
    padding: 0;
    border: 0;
    background-color: var(--black);
    margin-bottom: 20px;
}

#notificationSection .nav-tabs .nav-link h6 {
    font-family: "Inter-Regular";
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 20px;
}

#notificationSection .nav-tabs .nav-link svg {
    font-size: 13px;
}

#notificationSection .nav-tabs .nav-link span {
    font-size: 15px;
}

.common-form .detail p {
    margin-bottom: 4px;
}

.detail .img-box {
    text-align: right;
}

.detail .img-box img {
    max-width: 80%;
}

.common-form .button-box {
    margin-top: 55px;
}

.modal .modal-footer button {
    padding: 12px 0;
    font-weight: 800;
}

/* ******************** 

User side design css 

 ******************* */
#userSideDepositSection .common-form .button-box {
    margin-top: 140px;
}

#userSideDepositSection .modal .button-box button {
    padding: 10px 30px;
    font-weight: 800;
}

span.tooltips-bx {
    position: absolute;
    top: 46%;
    right: 3%;
    z-index: 88;
}

.tooltips-bx i {
    color: var(--white);
    transform: rotate(180deg);
    font-size: 18px;
}

/* #userSideWithdrawSection .common-form .button-box {
    margin-top: 10px;
} */

#userSideWithdrawSection .common-form .button-box {
    margin-top: 202px;
}

/*User page  section css ******************************************************************************/
#userSection .nav-tabs {
    row-gap: 20px;
}

#userSection .nav-tabs .nav-link {
    padding: 0;
    border: 0;
    background-color: var(--black);
}

#userSection .nav-tabs .nav-link .payment-card {
    width: 100%;
}

#userSection .nav-tabs .nav-link.active .payment-card {
    background: var(--active-color);
}

#userSection .payment-card {
    padding: 10px;
}

#userSection .payment-card .img-box {
    margin-right: 20px;
    text-align: left;
}

#userSection .payment-card .img-box img {
    border-radius: 12px;
}

#userSection .payment-card .modal-body .form-select {
    background-image: url('../images/down-icon.png');
    background-size: auto;
    background-position: 96% center;
    background-repeat: no-repeat;
}

#userSection .payment-card .modal-body .form-select:focus {
    border: 1px solid var(--border-color) !important;
}

/* block User page Css  ***********************************************************************/

#blockUserSection .nav-tabs .nav-link .payment-card {
    padding: 8px 8px;
    width: 100%;
}

#userTransactionSection .nav-tabs .nav-link:focus,
#userTransactionSection .nav-tabs .nav-link:hover,
#blockUserSection .nav-tabs .nav-link:focus,
#blockUserSection .nav-tabs .nav-link:hover {
    border-color: transparent;
    isolation: unset;
}

/* #blockUserSection .img-box{
    margin-right: 12px;
} */
#blockUserSection .img-box img {
    border-radius: 12px;
    width: 85%;
}

#blockUserSection .user-info h6 {
    font-family: "Inter-Regular";
    font-weight: 400;

}

#blockUserSection .button-box .btn-common {
    font-size: 18px;
    font-family: "Inter-SemiBold";
}

#blockUserSection .button-box .btn-common:hover a {
    color: var(--orange-E67A30) !important;
}

/* userTransactionSection css  */
#userTransactionSection nav {
    margin-bottom: 20px;
}

#userTransactionSection .nav-tabs .nav-link {
    width: 50%;
    padding: 15px 15px;
    border: 0;
    border-radius: 13px;
}

#userTransactionSection #nav-myID .btn-common {
    padding: 15px 0;
}

#userTransactionSection #nav-myID .btn-common img {
    margin-right: 0;
}

#userTransactionSection .payment-card {
    padding: 15px 0;
    margin-bottom: 20px;
    border-radius: 20px;
}

#userTransactionSection .payment-card .user-detail-box {
    padding: 0 10px 0 10px;
}

#userTransactionSection .payment-card .user-detail-box .user-info h5 {
    text-transform: lowercase;
}

#userTransactionSection #nav-Transaction .payment-card .button-row .link,
#userTransactionSection .payment-card .user-detail-box .link {
    text-transform: lowercase;
}

#userTransactionSection .payment-card .user-detail-box .img-box {
    margin-right: 20px;
}

#userTransactionSection .payment-card .user-detail-box .img-box img {
    width: 100%;
    max-width: 70px;
    border-radius: 18px;
}

#userTransactionSection .payment-card .user-detail-box .link:hover {
    color: inherit;
}

#userTransactionSection .button-row {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-top: 10px;
    border-top: 1px solid var(--border-color);
    padding: 15px 10px 0 10px;
}

#userTransactionSection #nav-Transaction .payment-card .img-box img {
    border-radius: 0;
}

#userTransactionSection #nav-Transaction .payment-card .button-row .img-box img {
    width: 37px;
    height: 37px;
    max-width: 100%;
    border-radius: 8px;
}

#userTransactionSection #nav-Transaction .payment-card .button-row h5 {
    font-size: 16px;
}

#userTransactionSection #nav-Transaction .payment-card .button-row h5 svg {
    margin-left: 6px;
}

/* Tost swal2 */
.custom-toast {
    z-index: 111112;
}

.main-header-box .call-setting-tab {
    display: none;
}

.main-header-box .call-setting-tab>a {
    background: var(--active-color);
}

#profileSection .common-form .input-group .image {
    margin: 0 auto;
    height: 150px;
    border-radius: 50% !important;
    overflow: hidden;
}

#profileSection .common-form  .input-group .image img.profile-update{
    max-width: 100%;
    width: 100%;
}

#profileSection .common-form .input-group .image img {
    max-width: 70px;
}

#profileSection .common-form .input-group .social-links {
    text-align: center;
    margin-top: 30px;
}

#profileSection .common-form .button-box {
    margin-top: 0;
    padding-top: 0;
}

#profileSection .common-form .button-box ul {
    justify-content: center;
    column-gap: 5px;
    row-gap: 10px;
}

#profileSection .common-form .btn-common {
    padding: 10px 15px;
    font-size: 17px;
    border-radius: 8px;
    background: var(--dark-bg-color);
    color: var(--white);
    transition: all 0.5s ease-in-out;
    width: 100%;
}

#profileSection .common-form .btn-common svg {
    height: 24px;
    width: 24px;
    min-width: 24px;
}

#profileSection .common-form .btn-common:hover {
    background-color: var(--orange-E67A30);
}

#profileSection .common-form .btn-common:hover span {
    color: var(--black);
}

#profileSection .common-form .btn-common svg path {
    fill: var(--white);
}

#profileSection .common-form .btn-common:hover svg path {
    fill: var(--black);
}

/* offer modal  */
.modal-body {
    /* height: 610px; */
    overflow: auto;
}

.offer {
    background: #131418;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;

}

.offer:last-child {
    margin-bottom: 0;
}

.offer .title-box p {
    display: inline-block;
    background: var(--dark-bg-color);
    border-radius: 5px;
    padding: 7px 30px;
    font-size: 22px;
}

.offer-content p {
    margin: 12px 0;
}

.offer-content ul {
    list-style: disc;
    padding-left: 18px;
}

.offer-content ul li {
    color: #8e9295;
    margin-bottom: 14px;
}

.promo-text span {
    color: #8e9295;
    display: block;
}

.offer .button-box ul li {
    color: #8e9295;
}

.offer .button-box ul li span.Percentage {
    color: var(--white);
    font-size: 20px;
    margin-left: 6px;
}

.modal .offer .button-box button {
    padding: 10px 50px !important;
    font-weight: 800;
}

/* / footer css  / */
.mobile-navbar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    opacity: 0;
    display: none;

}
.mobile-navbar .nav-bar-nav {
    display: flex;
    justify-content: space-around;
}
.mobile-navbar .main-navbar .nav{
    display: block;
    background-color: var(--dark-bg-color);
    border-radius: 40px 40px 0 0;

}

.mobile-navbar .nav-bar-nav .nav-item a.nav-link.demo {
    color: var(--text-color);
    font-size: 20px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0;
    transition: 0.6s ease;
}
.mobile-navbar a.nav-link .icon-box-outer{
    top: -20px;
transition: 0.6s ease;
}
.mobile-navbar a.nav-link.active .icon-box-outer {
    display: block;
    background: var(--black);
    border-radius: 0 0 50% 50%;
    border-radius: 50%;
    position: relative;
    top: -20px;
}

.mobile-navbar a.nav-link.demo .icon-box {
    margin: 11px 11px;
    padding: 20px;
    border-radius: 50%;
    height: 66px;
    width: 66px;
    transition: 0.6s ease;
    position: relative;

}

.mobile-navbar  a.nav-link.demo svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.mobile-navbar  a.nav-link.demo.active svg{
    fill:var(--white)
}

.lds-ring div {
    box-sizing: border-box;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Not fount list css */
.empty-data-message .logo-box img,
.modal  .modal-body .logo-box img {
    max-width: 14%;
}
.empty-data-message .text-box  span{
    font-size: 20px;
    color: #7a7a7a;
    padding-top: 25px;
} 